<template>
<v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card ref="formCard">
            <v-card-title>
                <span class="headline" v-if="formType === 'UPDATE'">Update Threshold</span>
                <span class="headline" v-else>Create New Threshold</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formEntry">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12>
                                <v-text-field label="Type*" :readonly="formType === 'UPDATE'" @input="v => { form.type = v ? v.toUpperCase() : undefined}"  v-model="form.type" required :rules="[rules.requiredField]" data-cy="form-type"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Threshold Amount*" @keypress="onlyForCurrency" v-model="form.amount" required :rules="[rules.requiredField]" data-cy="form-amount"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Threshold Count*" @keypress="isNumber($event)" v-model="form.count" required :rules="[rules.requiredField]" data-cy="form-count"></v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field label="Remarks" v-model="form.remarks" data-cy="form-remarks"></v-text-field>
                            </v-flex>
                            <v-flex xs12 sm6 md4 >
                                <v-switch v-model="form.isEnabled" :label="`Is Enabled: ${form.isEnabled ? form.isEnabled.toString() : 'false'}`" data-cy="form-switch"></v-switch>
                            </v-flex>
                            <!-- <v-flex xs12>
                                <v-select :items="merchantList" item-text="merchantName" item-value="id" label="Merchants*" v-model="form.merchants" required :rules="[rules.requiredSelection]" multiple return-object>
                                  <v-list-item
                                    slot="prepend-item"
                                    ripple
                                    @click="toggle"
                                    v-if="merchantList.length > 0"
                                  >
                                    <v-list-item-action>
                                      <v-icon :color="form.merchants.length > 0 ? 'indigo darken-4' : ''">{{ icon }}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title>Select All</v-list-item-title>
                                  </v-list-item>
                                  <v-divider
                                    slot="prepend-item"
                                    class="mt-2"
                                  ></v-divider>
                                  <v-divider
                                    slot="append-item"
                                    class="mb-2"
                                  ></v-divider>
                                </v-select>
                            </v-flex> -->
                        </v-layout>
                    </v-container>
                </v-form>
                <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text v-on:click="close" data-cy="close-button">Close</v-btn>
                <v-btn color="blue darken-1" text v-on:click="save" data-cy="save-button">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-layout>
</template>

<script>
export default {
    props: {
        isShow: Boolean,
        formType: String,
        loadingDialog: Boolean,
        selectedItem: Object,
    },
    computed: {
        populatedForm() {
            return {
                type: this.form.type,
                amount: this.form.amount,
                count: this.form.count,
                remarks: this.form.remarks,
                isEnabled: this.form.isEnabled,
                formType: this.formType
            }
        }
    },
    watch: {
        isShow: {
            handler(value) {
                this.dialog = value
            }
        },
        loadingDialog: {
            handler(value) {
                this.loading = value
            },
        },
        dialog: {
            handler(value) {
                if (value === true) {
                    this.initializeForm()
                }
            }
        },
        merchants: {
            handler(value) {
                this.merchantList = value
            }
        },
        deep: true,
    },
    data() {
        return {
            dialog: this.isShow ?? false,
            loading: this.loadingDialog ?? false,
            merchantList: this.merchants ?? [],
            form: {
              merchants: []
            },
            rules: {
                requiredField: v => !!v || "Requiredthis.price field.",
                requiredSelection: v => !!v && v.length > 0 || "Required at least one selection",
            }
        }
    },
    mounted() {
        this.initializeForm()
    },
    methods: {
        close() {
            this.dialog = false
            this.$refs.formEntry.reset()
            this.$refs.formCard.$el.scrollIntoView({
                behavior: 'smooth'
            })
            this.$emit('close-dialog', this.formType)
        },
        save() {
            if (this.$refs.formEntry.validate()) {
                this.$emit('save-threshold', this.populatedForm)
                this.$refs.formCard.$el.scrollIntoView({
                    behavior: 'smooth'
                })
            }
        },
        resetForm() {
            this.$refs.formEntry.reset()
        },
        initializeForm() {
            if (this.formType === 'CREATED') {
                this.form = {
                    type: undefined,
                    amount: undefined,
                    count: undefined,
                    remarks: undefined,
                    isEnabled: false
                }
            } else if (this.formType === 'UPDATE' && this.selectedItem) {
                this.form = {
                    type: this.selectedItem.type,
                    amount: this.selectedItem.amountThreshold,
                    count: this.selectedItem.countThreshold,
                    remarks: this.selectedItem.remarks,
                    isEnabled: this.selectedItem.isEnabled
                }
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        onlyForCurrency ($event) {
            // console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

            // only allow number and one dot
            if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.form.amount.indexOf('.') != -1)) { // 46 is dot
                $event.preventDefault();
            }

            // restrict to 2 decimal places
            if(this.form.amount!=null && this.form.amount.indexOf(".")>-1 && (this.form.amount.split('.')[1].length > 1)){
                $event.preventDefault();
            }
        }
    }
}
</script>
